import {SET_USER_IMAGE} from '../actions/types';


const userImage = (state = null, action) => {
  switch (action.type) {
    case SET_USER_IMAGE:
      return action.payload;
    default:
      return state;
  }
};

export default userImage;