import {SET_SUBSCRIPTION} from '../actions/types';


const subscription = (state = null, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION:
      return action.payload;
    default:
      return state;
  }
};

export default subscription;