import {SET_INFO} from '../actions/types';

const initialState = {
  shortPassword: 'Password must be at least 8 characters',
  passwordUpper: 'Password must contain upper case letters',
  passwordLower: 'Password must contain lower case letters',
  passwordSpecial: 'Password must contain special characters',
  passwordDigit: 'Password must contain digit characters',
  passwordsDoesNotMatch: 'Passwords does not matched',
  somethingWentWrong: 'Something went wrong, try it later',
  invalidCode: 'Code is invalid, try again',
  somethingWentWrongTryAgain: 'Something went wrong, try again later',
  yourMessageHasBeenSent: 'Your message has been sent',
  contactUsMessage: 'Got a technical issue? Want to send feedback about a feature? Need help with your account or billing? Let us know.',
}
const info = (state= initialState, action) => {
  switch (action.type) {
    case SET_INFO:
      return action.payload;
    default:
      return state;
  }
};

export default info;