import * as types from './types';

export const setUser = user => ({
  type: types.SET_USER,
  payload: user
});

export const setMessage = data => ({
  type: types.SET_MESSAGE,
  payload: data
});
export const setInterpretations = data => ({
  type: types.SET_INTERPRETATIONS,
  payload: data
});

export const setConfig = data => ({
  type: types.SET_CONFIG,
  payload: data
});

export const setEmailVerification = data => ({
  type: types.SET_EMAIL_VERIFICATION,
  payload: data
});

export const setCards = data => ({
  type: types.SET_CARDS,
  payload: data
});

export const setSpreads = data => ({
  type: types.SET_SPREADS,
  payload: data
});

export const setAccessToken = data => ({
  type: types.SET_ACCESS_TOKEN,
  payload: data
});

export const setRefreshToken = data => ({
  type: types.SET_REFRESH_TOKEN,
  payload: data
});

export const setPersonalReadings = data => ({
  type: types.SET_PERSONAL_READINGS,
  payload: data
});

export const setAIReadings = data => ({
  type: types.SET_AI_READINGS,
  payload: data
});

export const setAISession = data => ({
  type: types.SET_AI_SESSION,
  payload: data
});


export const setAIReading = data => ({
  type: types.SET_AI_READING,
  payload: data
});


export const setPlans = data => ({
  type: types.SET_PLANS,
  payload: data
});
export const setSubscription = data => ({
  type: types.SET_SUBSCRIPTION,
  payload: data
});

export const setPlanTypes = data => ({
  type: types.SET_PLAN_TYPES,
  payload: data
});


export const setCustomer = data => ({
  type: types.SET_CUSTOMER,
  payload: data
});


export const setFreePersonalReading = data => ({
  type: types.SET_FREE_PERSONAL_READING,
  payload: data
});


export const setFreeAIReading = data => ({
  type: types.SET_FREE_AI_READING,
  payload: data
});



export const setUserImage = data => ({
  type: types.SET_USER_IMAGE,
  payload: data
});


export const setRemainingAICount = data => ({
  type: types.SET_REMAINING_AI_COUNT,
  payload: data
});

