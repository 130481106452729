import api from "./requests/api";
import store from "./store";

const urls = {...store.getState().config.urls};
export const fetchRefreshToken = async () => {
  return await api.get(`${urls.auth}${urls.refresh}/`);
};

const fetchData = async url => {
  const res = await api.get(url);
  if (res?.status === 200 && res?.data) return res.data;
  else return undefined;
}

export const fetchCards = async () => fetchData(`${urls.cards}/`);



export const fetchSpreads = async () => fetchData(`${urls.spreads}/`);


export const fetchAIReadings = async () => fetchData(`${urls.oracle}/`);

export const fetchPersonalReadings = async () => fetchData(`${urls.readings}/`);

export const fetchCustomer = async () => fetchData(`${urls.stripe}${urls.customer}/`)

export const fetchSubscription = async () => fetchData(`${urls.stripe}${urls.subscription}/`)

export const fetchCustomerPortal = async () => fetchData(`${urls.stripe}${urls.customerPortal}/`)
export const fetchFreePersonalReading = async () => fetchData(`${urls.oneFreePersonal}/`)

export const fetchFreeAIReading = async () => fetchData(`${urls.oneFreeAI}/`)

export const fetchRemainingAIReadings = async () => fetchData(`${urls.oracle}${urls.remaining}/`)

export const fetchArticles = async (page, amount) => fetchData(`${urls.articles}/?page=${page}&amount=${amount}`);
