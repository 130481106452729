import {SET_REMAINING_AI_COUNT} from '../actions/types';


const remainingAICount = (state = null, action) => {
  switch (action.type) {
    case SET_REMAINING_AI_COUNT:
      return action.payload;
    default:
      return state;
  }
};

export default remainingAICount;