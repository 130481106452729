import {SET_FREE_AI_READING} from '../actions/types';


const freeAIReading = (state = null, action) => {
  switch (action.type) {
    case SET_FREE_AI_READING:
      return action.payload;
    default:
      return state;
  }
};

export default freeAIReading;