import {SET_CONFIG} from '../actions/types';

const initialState = {
  version: '0.0.1',
  urls: {
    api: '/api',
    auth: '/auth',
    articles: '/articles',
    refresh: '/refresh',
    qrcode: '/qrcode',
    me: '/me',
    users: '/users',
    verifyEmail: '/verify-email',
    login: '/auth/login',
    logout: '/auth/logout',
    googleLogin: '/auth/google/',
    cards: '/cards',
    spreads: '/spreads',
    yesOrNo: '/yes-or-no',
    interpretations: '/interpretations',
    userDailyReadings: '/user-daily-readings',
    userYesNoReadings: '/user-yesno-readings',
    readings: '/readings',
    contactMessages: '/contact-messages',
    oracle: '/oracle',
    plans: '/plans',
    planTypes: '/plan-types',
    subscriptions: '/subscriptions',
    stripe: '/stripe',
    customer: '/customer',
    subscription: '/subscription',
    customerPortal: '/customer-portal',
    oneFreePersonal: '/one-free-personal',
    oneFreeAI: '/one-free-ai',
    remaining: '/remaining',
    tags: '/tags',
    images: '/images',
    create: '/create',
  },
  routes: {
    admin: '/admin',
    articles: '/articles',
    article: '/article',
    home: '/',
    login: '/login',
    logout: '/logout',
    editProfile: '/edit-profile',
    editNotifications: '/edit-notifications',
    register: '/register',
    profile: '/profile',
    security: '/security',
    planBilling: '/plan-billing',
    shop: '/shop',
    dashboard: '/dashboard',
    tarotpedia: '/tarotpedia',
    emailConformation: '/email-confirmation',
    contact : '/contact',
    faq: '/faq',
    termsOfService : '/terms-of-service',
    privacyPolicy : '/privacy-policy',
    pastPresentFuture : '/past-present-future',
    crush: '/crush',
    heartbreak: '/heartbreak',
    action: '/action',
    celticCross: '/celtic-cross',
    zodiac: '/zodiac',
    categories: '/categories',
    dailyReadings: '/daily-reading',
    dailyReadingCategories: '/daily-reading-categories',
    dailyLove: '/daily-love',
    dailyGeneral: '/daily-general',
    dailyFinance: '/daily-finance',
    dailyHealth: '/daily-health',
    yesNoReadings: '/yes-no-reading',
    yesNoLove: '/yes-no-love',
    yesNoGeneral: '/yes-no-general',
    yesNoFinance: '/yes-no-finance',
    yesNoHealth: '/yes-no-health',
    personalReadings: '/personal-readings',
    pastPresentFutureReading: '/past-present-future-reading',
    crushReading: '/crush-reading',
    heartbreakReading: '/heartbreak-reading',
    actionReading: '/action-reading',
    celticCrossReading: '/celtic-cross-reading',
    zodiacReading: '/zodiac-reading',
    aiReadings: '/ai-readings',
    question: '/question',
    cardSelection: '/card-selection',
    aiFinanceReading: '/ai-finance-reading',
    aiHealthReading: '/ai-health-reading',
    wands: '/wands',
    cups: '/cups',
    swords: '/swords',
    pentacles: '/pentacles',
    trumps: '/trump',
    suits: '/suits',
    howTo: '/how-to',
    howToReadCards: '/read-tarot-cards',
    howToReversed: '/use-reversed-cards',
    howToCleanse: '/cleansing-and-storing-your-tarot-cards',
    howToQuestions: '/formulating-your-own-questions-for-tarot-readings',
    howToSpreads: '/creating-your-own-spreads-for-tarot-readings',
    history: '/history',
    meditation: '/meditation',
    tarotastrology: '/tarot-astrology',
    misconceptions: '/misconceptions',
    courtCards: '/court-cards',
    intuition: '/intuition',
    readings: '/readings',
    oracle: '/oracle',
    qrcode: '/qrcode',
    create: '/create',
    legal: '/legal',
    terms: '/terms',
    privacy: '/privacy',
  },
  labels: {
    "Celtic Cross": ['Present Situation', 'Challenge', 'Root Cause', 'Distant Past', 'Recent Past', 'Future Influences', 'Querent\'s Attitude', ' Others View', 'Hopes and Fears', 'Outcome'],
    "Action Spread": ['Situation','Action', 'Outcome'],
    "Past, Present, Future": ['Past', 'Present', 'Future'],
    "Crush Spread": ['Their Qualities', 'Their Feelings', 'Obstacles', 'Your Approach', 'Future'],
    "Heartbreak Spread": ['Factors', 'Lessons', 'Healing', 'Future'],
    "Zodiac Spread": ['1st House', '2nd House', '3rd House', '4th House', '5th House', '6th House', '7th House', '8th House','9th House', '10th House', '11th House', '12th House'],
  }
}
const config = (state= initialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return action.payload;
    default:
      return state;
  }
};

export default config;