import React, {Suspense, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Loader from "./components/Misc/Loader";
import './styles/tailwind.css';
import './App.css';
import './styles/main.scss'
import {lazy} from '@loadable/component'
import {useQuery} from "react-query";
import {fetchRefreshToken} from "./queries";
import {setAccessToken, setRefreshToken, setUser} from "./actions";

const HomePage = lazy(() => import('./pages/HomePage'))
const Logout = lazy(() => import('./pages/Logout'))
const Readings = lazy(() => import('./pages/Readings'))
const AIReading = lazy(() => import('./pages/AIReading'))
const OracleReadings = lazy(() => import('./pages/Readings/OracleReadings'))
const DataManager = lazy(() => import('./components/DataManager'))
const Login = lazy(() => import('./pages/Login'))
const NotFound = lazy(() => import('./pages/NotFound'))
const Register = lazy(() => import('./pages/Register'))
const ProfileRouter = lazy(() => import('./pages/Profile'))
const EmailConfirmation = lazy(() => import('./pages/EmailConformation'))
const Notification = lazy(() => import('./components/Misc/Notification'))
const TarotpediaRouter = lazy(() => import('./pages/Tarotpedia'))
const ArticlesRouter = lazy(() => import('./pages/Articles/ArticlesRouter'))
const DailyReadings = lazy(() => import('./pages/DailyReadings'))
const YesNoRouter = lazy(() => import('./pages/DailyReadings/YesNoRouter'))
const PersonalReadings = lazy(() => import('./pages/PersonalReadings'))
const Admin = lazy(() => import('./pages/Admin'))
const Legal = lazy(() => import('./pages/Legal'))
const analytics = lazy(() => import('./Configuration/Firebase').then(module => module.analytics));



function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  analytics && analytics.load();
  const userData = useQuery({
    queryKey: ['userData'],
    queryFn: fetchRefreshToken,
    refetchInterval: 1000 * 60 * 29, // 29 minutes
    enabled: Boolean(user),
  })
  useEffect(() => {
    if (userData.data?.data?.user) {
      dispatch(setUser(userData.data.data.user));
      dispatch(setAccessToken(userData.data.data.access_token));
      dispatch(setRefreshToken(userData.data.data.refresh_token));
    }
  }, [userData.data])
  const {routes} = useSelector(state => state.config);
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="preconnect" href="https://api.pagetarot.net"/>
      </Helmet>
      <Router>
        <Suspense fallback={<Loader/>}>
          <DataManager/>
        </Suspense>
        <Routes>
          <Route path={`${routes.home}/*`} element={<Suspense fallback={<Loader/>}><HomePage/></Suspense>}/>
          <Route path={routes.login} element={<Suspense fallback={<Loader/>}><Login/></Suspense>}/>
          <Route path={routes.logout} element={<Suspense fallback={<Loader/>}><Logout/></Suspense>}/>
          <Route path={routes.register} element={<Suspense fallback={<Loader/>}><Register/></Suspense>}/>
          <Route path={`${routes.readings}/:url`} element={<Suspense fallback={<Loader/>}><Readings/></Suspense>}/>
          <Route path={`${routes.oracle}/:url`} element={<Suspense fallback={<Loader/>}><OracleReadings/></Suspense>}/>
          <Route path={`${routes.legal}/:slug`} element={<Suspense fallback={<Loader/>}><Legal/></Suspense>}/>
          <Route path={`${routes.profile}/*`} element={<Suspense fallback={<Loader/>}><ProfileRouter/></Suspense>}/>
          <Route path={`${routes.admin}/*`} element={<Suspense fallback={<Loader/>}><Admin/></Suspense>}/>
          <Route path={`${routes.dailyReadings}/*`} element={<Suspense fallback={<Loader/>}><DailyReadings/></Suspense>}/>
          <Route path={`${routes.yesNoReadings}/*`} element={<Suspense fallback={<Loader/>}><YesNoRouter/></Suspense>}/>
          <Route path={`${routes.personalReadings}/*`}
                 element={<Suspense fallback={<Loader/>}><PersonalReadings/></Suspense>}/>
          <Route path={`${routes.aiReadings}/*`} element={<Suspense fallback={<Loader/>}><AIReading/></Suspense>}/>
          <Route path={`${routes.tarotpedia}/*`} element={<Suspense fallback={<Loader/>}><TarotpediaRouter/></Suspense>}/>
          <Route path={`${routes.articles}/*`} element={<Suspense fallback={<Loader/>}><ArticlesRouter/></Suspense>}/>
          <Route path={`${routes.emailConformation}/:uid/:code/:email`}
                 element={<Suspense fallback={<Loader/>}><EmailConfirmation/></Suspense>}/>
          <Route path="*" element={<Suspense fallback={<Loader/>}><NotFound/></Suspense>}/>
        </Routes>
        <Notification/>
      </Router>
    </HelmetProvider>
  );
}

export default App;
