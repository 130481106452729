import {SET_FREE_PERSONAL_READING} from '../actions/types';


const freePersonalReading = (state = null, action) => {
  switch (action.type) {
    case SET_FREE_PERSONAL_READING:
      return action.payload;
    default:
      return state;
  }
};

export default freePersonalReading;