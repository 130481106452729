import {combineReducers} from "redux";
import message from "./message";
import user from "./user";
import config from "./config";
import icons from "./icons";
import info from "./info";
import emailVerification from "./emailVerification";
import cards from "./cards";
import spreads from "./spreads";
import accessToken from "./accessToken";
import refreshToken from "./refreshToken";
import interpretations from "./interpretations";
import personalReadings from "./personalReadings";
import aiReadings from "./aiReadings";
import aiSession from "./aiSession";
import aiReading from "./aiReading";
import plans from "./plans";
import subscription from "./subscription";
import customer from "./customer";
import freePersonalReading from "./freePersonalReading";
import freeAIReading from "./freeAIReading";
import userImage from "./userImage";
import remainingAICount from "./remainingAICount";


export default combineReducers({
  config,
  message,
  user,
  icons,
  info,
  emailVerification,
  cards,
  spreads,
  accessToken,
  refreshToken,
  interpretations,
  personalReadings,
  aiReadings,
  aiSession,
  aiReading,
  plans,
  subscription,
  customer,
  freePersonalReading,
  freeAIReading,
  userImage,
  remainingAICount,
});