import axios from 'axios';
import store from "../store";


export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://pagetarot.co/v1';
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const getState = () => store.getState();

// Function to handle errors globally
const handleError = (error) => {
  console.log('error: ', error);

  // Return a resolved Promise with a default value
  return Promise.resolve({data: {error: error}});
};

// Add a request interceptor
api.interceptors.request.use((config) => {
  // Get the access token from the Redux store
  const accessToken = getState().accessToken || '';
  // If the access token exists, set it in the Authorization header
  const refreshToken = getState().refreshToken || '';

  if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
  if (refreshToken) config.headers['X-Refresh-Token'] = `${refreshToken}`;

  return config;
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => response, // Pass the response if it's successful
  (error) => handleError(error) // Call the handleError function if there's an error
);
api.defaults.withCredentials = true;

export default api;
