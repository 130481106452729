export const SET_USER = 'SET_USER';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_ICONS = 'SET_ICONS';
export const SET_INFO = 'SET_INFO';
export const SET_EMAIL_VERIFICATION = 'SET_EMAIL_VERIFICATION';
export const SET_CARDS = 'SET_CARDS';
export const SET_INTERPRETATIONS = 'SET_INTERPRETATIONS';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_SPREADS = 'SET_SPREADS';
export const SET_PERSONAL_READINGS = 'SET_PERSONAL_READINGS';
export const SET_AI_READINGS = 'SET_AI_READINGS';
export const SET_AI_SESSION = 'SET_AI_SESSION';
export const SET_AI_READING = 'SET_AI_READING';
export const SET_PLANS = 'SET_PLANS';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_PLAN_TYPES = 'SET_PLAN_TYPES';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_FREE_PERSONAL_READING = 'SET_FREE_PERSONAL_READING';
export const SET_FREE_AI_READING = 'SET_FREE_AI_READING';
export const SET_USER_IMAGE = 'SET_USER_IMAGE';
export const SET_REMAINING_AI_COUNT = 'SET_REMAINING_AI_COUNT';