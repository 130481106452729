import {SET_AI_READINGS} from '../actions/types';


const aiReadings = (state = null, action) => {
  switch (action.type) {
    case SET_AI_READINGS:
      return action.payload;
    default:
      return state;
  }
};

export default aiReadings;